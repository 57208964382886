.App{
    /* display: flex;
    flex-direction: column;
    gap: 6rem; */
    overflow: hidden;
}
.btn{
    background-color: cadetblue;
    padding: 0.8rem;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .stroke-text{
    color:transparent;
    font-family: Arial, Helvetica, sans-serif;
  
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }
  .flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }