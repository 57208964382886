.ourTeam{
  background-color: #151515;
 height: 850px;
   display: flex;
  flex-direction: column;
  /* gap:1rem; */
  /* position: relative; */
  /* padding: 0 10vw; */
 
}
.ourTeamTest{
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 5rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
 margin-top: 3rem;
 margin-bottom: 3rem;
}
.ourTeamTest>:nth-child(2){
  color: cadetblue;
 
}


.slick-slide > div{
  /* margin: 0 auto; */
  margin-left: 4rem;
  display: flex;
  width: 500px;
  align-items: center;
}
.slick-list{
  width: 100%;
  position: relative;
  left: 4rem;
  /* align-items: center; */
  /* justify-content: center; */
  /* margin-left: 10rem; */
  /* margin: 0 au/to; */
  /* background-color: white; */
}
.slick-active{
  color:white;
}
.ourTeamTop > img{
  /* margin: 0 7.5rem; */
  /* width: 50%; */
  object-fit: cover;
  margin-left: 2rem;
  opacity: 1;
  width: 100%;
  height: auto;
  transition:  .5s ease;
  backface-visibility: hidden;
  /* border-radius: 5%; */
  /* border: 5px solid yellowgreen; */
  /* background-color: yellow; */
}

.ourTeamTop:hover img{
  opacity: 0.3;
}

.ourTeamTop:hover .content-details{
  opacity: 1;
}
/* .ourTeamTop:hover > img{
opacity: 1;
} */
.ourTeamTop{
  position: relative;
 /* height: 650px; */
 /* width: 200px; */
 /* width: 50%; */
}



.slick-dots{
 bottom: -50px !important;
}
.slick-dots li {
width: 10px;
height: 10px;
margin: 5rem 5rem;
padding: 10rem;
background-color: cadetblue;

color: white;

}
.slick-dots li button{
  width: 10px;
  padding: 20px;
}

.content-details{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 94%;
  left: 21.5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: cadetblue;
  color: white;
  font-size: 16px;
  padding: 0.3rem 9.66rem;
}



  @media screen and (max-width: 835px) {

    .ourTeamTest{
      font-size: 3rem;
      flex-direction: column;
      text-align: center;
      gap: 1rem;
        }
    .slick-list{
      width: 150%;
      left: 1.1rem;
        }
  }
  .slick-slide > div{
margin-left: 3rem;
  }
  .ourTeamTop > img{
margin-left: -5rem;

width: 75%;




}