.plans-container{
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
    background: #1a1818;
    width: 100%;
    height: 100%;
    

}
.plans-header
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    gap: 1rem;
    font-size: 4rem;
    font-weight: bold;
}
.plans-header>:nth-child(2){
    color: cadetblue;
}
.plans{
    display: flex;
    align-items: center ;
    justify-content: center;
    gap: 3rem;
    /* height: 10rem; */

    width: 100%;
    height: 50rem;
    /* padding: 4rem; */
    /* border: 1px solid white; */

}
.plan{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 2rem;
   
width: 25rem;
padding: 4rem 1rem;
   /* padding: 3rem 3rem; */
   border:1px solid white;
 /* border-radius: 10px; */
    /* transform: skew(-4deg); */
    border-top-left-radius: 10%;
    border-bottom-right-radius: 10%;
}

.plan:hover{
    background-color: white;
    color: black;
    transition: all 0.5s ease-in-out;
}
.plan>:nth-child(3){
    color: cadetblue;
    /* gap: 1rem; */
}
.plan>:nth-child(3)::before{
    content:'$';
      
}
.plans>:nth-child(2){
    transform: scale(1.1);
}

.plan>svg{
    width: 2rem;
    height: 2rem;
    fill: cadetblue;
}
.plan>:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 1.2rem;
    color: rgb(132, 134, 136);
}
.planBtn{
    padding: 0.7rem 5rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 300;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature>img{
    width: 1rem;
    filter: opacity(0.4) drop-shadow(0 0 0 cadetblue); 
  
}


@media screen and (max-width: 800px) {
    .plans-container{
        height: 1700px;
        /* justify-content: center; */
    }
    .plans-header{
        font-size: 2rem;
        flex-direction: column;
        gap:1rem;
        /* justify-content: flex-start;
        align-items: flex-start; */
    }
.plans{
padding: 4rem 0rem;

    flex-direction: column;
    /* width: 50%;
    height: 20rem; */
/* margin-top: 50rem; */

}
.plans{
    margin-top: 15rem;
    gap: 5rem;
}
.plan{
    width: 20rem;
    height: 25rem;
    border-top-left-radius: 0%;
    border-bottom-right-radius: 0%;
}
.planBtn{
    padding: 0.3rem 3rem;
    font-size: 0.7rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 300;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 1.7rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.7rem;
    color: rgb(132, 134, 136);
    
}
.plans>:nth-child(2){
    transform: none;
}
.plan>svg{
    width: 4rem;
    height: 4rem;
    fill: cadetblue;
}
}