.hero{
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../assets/anastase-maragos-7kEpUPB8vNk-unsplash.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 1040px;
    align-items: center;
    position: relative;
    
}
.hero-text-overlay{
    display: flex;

    flex-direction: column;
    text-align: center;
    color: white;
    position: absolute;
    right: 5rem;
    top: 8rem;
    text-transform: uppercase;
    font-size: 4rem;
    font-weight: bold;
    z-index: 1111;
    


}
.hero-text-overlay>div:nth-of-type(2){
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 1px;
    margin-right: 15rem;
    margin-bottom: 1.3rem;
 
}

.hero-text-overlay>div:nth-of-type(3){
    margin-right: 9.9rem;
}
.hero-text-overlay>div:nth-of-type(3) > span :nth-child(1){
color: cadetblue;

}
.right-overlay{
    float: right;
    background:linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    height: 890px;
    width: 600px;
   
    transform: rotate(8deg);
    margin-right: 4rem;
    z-index: -1;
    gap: 1.5rem;
}
.hero-btn{
    color: white;
   text-transform: uppercase;
    width:8rem;
   margin-top: 2rem;
   cursor: pointer;
   
}
.hero-btn:hover{
transform: scale(1.2);
}
.figures{
    margin-top: 1.5rem;
    display: flex;
    gap: 2rem;
    font-size: 1rem;
    font-weight: 200;
}
.figures >div{
    display: flex;
    flex-direction: column;
   
}

.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
    color: rgb(170, 159, 159);
    text-transform: uppercase;
    font-weight: 300;
    margin-top: .5rem;
}

.the-best-ad{
    font-size: 1rem;
    margin-bottom: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color:white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad > div{
    position: absolute;
    background-color: cadetblue;
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

@media screen and (max-width: 830px) {
    .hero{
        height: 100vh;
        background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../assets/anastase-maragos-7kEpUPB8vNk-unsplash.jpg');
        background-size: 1400px;
        background-repeat: no-repeat;
        background-position: top center;
        align-items: center;
        position: relative;
        
    }
    .right-overlay{
      
        display: none;
    }
    .hero-text-overlay{
        display: flex;
    
        flex-direction: column;
        text-align: center;
        color: white;
        position: absolute;
        right: 1.5rem;
        /* top: 12rem; */
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: bold;
        z-index: 1111;
        
    
    
    }
    .the-best-ad{
        margin-top: 2rem;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }

    .the-best-ad > div{
        position: absolute;
        background-color: cadetblue;
        width: 4rem;
        height: 80%;
        left: 8px;
        border-radius: 3rem;
        z-index: 1;
    }

    .hero-text-overlay{
font-size: xx-large;
align-items: center;
justify-content: center;
    }

    .hero-text-overlay>div:nth-of-type(2){
        font-size:1.5rem;
        font-weight: 400;
        letter-spacing: 1px;
        text-align: center;
        align-items: center;
        margin-right: 0;
        margin-bottom: 1.3rem;
     
    }

    .hero-text-overlay>div:nth-of-type(3){
   margin-right: 0;
    }
    .figures > div > span:nth-of-type(1){
        font-size: large;
        font-weight: 500;
    }
    .figures > div > span:nth-of-type(2){
        font-size: small;
    }

}