.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #252839;

}

.loader h2{
    position: relative;
    font-size: 14vw;
    color: #252839;
    -webkit-text-stroke:0.5vw #383d52;
    text-transform: uppercase;
    white-space: nowrap;
}
h2::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    color: cadetblue;
    -webkit-text-stroke:0vw #383d52;
    border-right: 2px solid cadetblue;
    overflow: hidden;
    animation:  animate 6s linear infinite;
}

@keyframes animate {
    0%,10%,100%{
        width: 0;
    }
    70%, 90%{
        width: 100%;
    }
    
}