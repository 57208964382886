.Footer-container{
    position: relative;
    background-color: #151515;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.Footer-container>hr{
    border: 1px solid gray;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
 gap: 4rem;
 align-items: center;
 justify-content: center;
 height: 20rem; 
 margin-top: 8rem;
}

.social-links{
    display: flex;
gap: 4rem;
}
.social-links>img{
    width: 3.5rem;
    height: 3.5rem;
    cursor: pointer;
}
.logo-f>img{
    width: 20rem;
    filter: opacity(0.5) drop-shadow(0 0 0 white); 
}
.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(196, 181, 181);
}

.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(238, 3, 3)
}