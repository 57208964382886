.offer{
    background: #1a1818;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.offer-text{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 5rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
   margin-top: 5rem;

    
}
.offer-text>:nth-child(2){
    color: cadetblue;
   
}
.offer-text>:nth-child(3){
    color: cadetblue;
   
}

.offers-category{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    /* background: #151515; */
    /* width: 50%; */
}

.offer-cat-img>img{
    width: 100%;
    border-bottom: 4px solid grey;
    object-fit: cover;
    
}
.offer-cat{
    width: 30%;
    /* height:  280px; */
    margin: 2rem auto;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    background: rgb(20, 10, 10);
    box-sizing: border-box;
}
.offers-category>:nth-child(4){
   width: 46%;
}
.offers-category>:nth-child(5){
   width: 46%;
}
.offer-cat-text{
color: white;
width: 100%;
display: flex;
flex-direction: column;
margin: 2rem;

}
.offer-cat-text>:nth-child(1){
    font-size: 1rem;
    font-weight: bold;
    color: cadetblue;
    text-transform: uppercase;
}
.offer-cat-text>:nth-child(2){
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.offer-display{
    display: flex;
    /* flex-direction: column; */
}
.offer-btn{
    color:white;
    align-self: center;
    margin-right: 1.2rem;;
    background: rgb(37, 34, 34);
    border-radius:5px;
    padding: 0.8rem;
}

.offer-btn:hover{
    background-color: #3a3737;
/* transform: scaleY(1); */
}


@media screen and (max-width: 800px) {

.offer-text{
    font-size: 2rem;
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
}

.offers-category{
flex-direction: column;
}
.offer-cat{
    width: 100%;

}

.offers-category>:nth-child(4){
width: 100%;
}
.offers-category>:nth-child(5){
width: 100%;
}
}