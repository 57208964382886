li{
    list-style: none;
}
a{
    text-decoration: none;
    color: rgb(204, 204, 204);
}
.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:15vh;
    width: 100%;
    
    /* background: rgb(0, 33, 65) */
}
.nav__brand{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2rem;
    margin-left: 4rem;
    color: cadetblue;
}
.nav__menu{
    display: flex;
    align-items: center;
    gap: 3rem;
    /* margin-right: 4rem; */
}
.nav__item{
    font-weight: bold;
}
 
.header-links{
    display: flex;
    gap: 3rem;
margin-left: 10rem;
margin-right: 2rem;
cursor: pointer;
}

.header-links > img{
    width: 1.3rem;
    height: 1.3rem;
}
.header-links > img:hover{
    opacity:0.3; 
}


.nav__toggler{
    position: absolute;
        z-index: 3;
        right: 35px;
        top: 35px;
        cursor: pointer;
        transition: all 0.5s ease-out;
    display: none;
}
.nav__toggler div{
width: 28px;
height: 3px;
margin: 0 0 10px 0;
background: white;
transition: all 0.5s ease-out;

}

.nav__item:hover{
    opacity: 0.7;
    text-decoration: line-through;
}

@media screen and (max-width:830px) {
    .nav__toggler{
        display: block;
        cursor: pointer;
        /* position: fixed; */
    }
    .nav__menu{
        position: fixed;
        top: 0vh;
        left: 0;
        height: 100vh;
        width: 50vw;
        background: rgb(0, 33, 65);
        flex-direction: column;
        z-index: 99999;
        transform: translateX(-100%);
        transition: 0.5s ease-in;
        gap: 1.2rem;
        /* margin: 5rem; */
    }
    .nav__menu > li{
        margin-top: 4rem;
        /* background-color: yellow; */
        /* position: absolute; */
        /* margin: 5rem; */
    }
    .nav__item{
        margin: 0rem;
        margin-top: 4rem;
    }

    .nav__active{
        transform: rotate(0deg);
    }

    /**Toggle Icon animation*/
    .toggle .line1{
        transform: rotate(-45deg) translate(-5px, 11px);
    }
    .toggle .line2{
        opacity: 0;
    }

    .toggle .line3{
        transform: rotate(45deg) translate(-7px, -13px);
    }

    .header-links{
        display: flex;
        gap: 1rem;
        margin-top: 4rem;
    margin-left: 0rem;
    margin-right: 0rem;

    }

}